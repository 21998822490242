<script lang="ts">
  import classnames from "classnames";
	import { createEventDispatcher } from "svelte";
  // Default Prop
  export let checked: boolean = false;
  export let error: string | null = null;
  export let required: boolean = false;
  export let disabled: boolean = false;

  const dispatch = createEventDispatcher();

  const onInput = (event) => {
    dispatch("input", event);
  };
</script>

<label class={classnames("checkbox-label", $$props.class)} for={$$restProps.id || $$restProps.name}>
  <input
    on:click
    on:input={onInput}
    id={$$restProps.id || $$restProps.name}
    type="checkbox"
    class:error={error}
    name={$$restProps.name}
    bind:checked
    {...$$restProps}
  />
  <span class="checkbox-square" />
  <span class="checkbox-text">
    <slot />
  </span>
</label>

{#if error}
  <span class="error">{error}</span>
{/if}

<style type="text/scss" lang="scss" scoped>
  .error {
    @apply text-xs;
    @apply text-error;
  }

  /* label */
  .checkbox-label {
    @apply flex justify-start flex-nowrap;
    @apply relative;
  }

  /* input */
  input[type="checkbox"] {
    @apply absolute;
    clip: rect(0, 0, 0, 0);
  }

  input[type="checkbox"]:not(:disabled) ~ .checkbox-square {
    @apply cursor-pointer;
  }

  /* .design */
  .checkbox-square {
    @apply w-5 h-5;
    @apply flex-shrink-0;
    @apply bg-white;
    @apply border-2 border-gray-600 border-opacity-50 rounded-sm;
    @apply relative;
    @apply ease-in-out duration-200;
    transition-property: border-color, opacity;
  }

  /* Checked/Hover */
  input[type="checkbox"]:not(:disabled):checked + .checkbox-square,
  input[type="checkbox"]:not(:disabled):hover + .checkbox-square,
  input[type="checkbox"]:not(:disabled):focus + .checkbox-square {
    @apply border-gray-900;
  }

  .checkbox-square + .checkbox-text {
    @apply ml-4;
  }

  .checkbox-text {
    @apply flex-1;
    @apply text-gray-900 font-sans text-sm;
    @apply ease-in-out duration-200;
    transition-property: opacity;
  }

  .checkbox-square::before,
  .checkbox-square::after {
    @apply absolute scale-x-0 origin-center;
    /* Offset the circle because of border of the parent */
    @apply -top-0.5 -left-0.5;
    content: "";
    width: inherit;
    height: inherit;
    border-radius: inherit;
  }

  /* MAIN SQUARE
  visibile in the checked state */
  .checkbox-square:before {
    @apply ease-in-out duration-200;
    @apply bg-gray-900 bg-no-repeat bg-center;
    @apply opacity-0;
    @apply transform scale-75;
    transition-property: background-color, transform, opacity;
    background-size: 50% 50%;
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 9 7'%3E%3Cpath fill='%23fff' fill-rule='evenodd' d='M8.146.146a.5.5 0 01.765.638l-.057.07-5.5 5.5a.5.5 0 01-.638.057l-.07-.057-2.5-2.5a.5.5 0 01.638-.765l.07.057L3 5.293 8.146.146z'/%3E%3C/svg%3E");
  }

  /* => Checked */
  input[type="checkbox"]:checked + .checkbox-square::before {
    @apply opacity-100 scale-100;
  }

  /* Focus */
  input[type="checkbox"]:not(:disabled):focus + .checkbox-square {
    @apply ring-2 ring-offset-2 ring-brand ring-opacity-50;
  }

  /* Ripple effect pseudo-element */
  .checkbox-square::after {
    @apply ease-in-out duration-200;
    @apply bg-brand;
    @apply opacity-0;
    @apply -z-1;
    transition-property: transform, opacity;
  }

  input[type="checkbox"]:checked + .checkbox-square::after {
    @apply animate-ripple-effect;
  }

  /* DISABLED */
  input[type="checkbox"]:disabled + .checkbox-square,
  input[type="checkbox"]:disabled ~ .checkbox-text {
    @apply opacity-50;
    @apply cursor-default;
  }
</style>
